<template>
  <div class="">
    <!-- 搜素记录 -->
    <div class="searchHistory" v-show="search.length > 0">
      <div class="historyHead">
        <div>{{$t('searchHistory')}}</div>
        <div @click="onDelete()">
          <van-icon name="delete-o"/>
        </div>
      </div>
      <div class="historys">
        <van-tag v-for="index in search" :key="index" @click="toSearch(index)">{{ index }}</van-tag>
      </div>
    </div>
    <!-- 热搜 -->
    <div class="recommend">
      <div class="recommendTop">
        {{$t('researchPopularity')}}
      </div>
      <div class="recommendList" v-for="item in hotInfo" :key="item.name">
        <div class="recommendList-name" @click="toSearch(item.name)">
          <div>{{ item.name }}</div>
          <div v-show="item.hot_type == 1"><img src="../../assets/HOT.png" alt=""></div>
        </div>
        <div class="recommendList-hint" v-if="false">13131</div>
      </div>
    </div>
    <!-- 弹出框 -->
  </div>
</template>
<script>
import {getHot} from '@/api/searchs'

import {Dialog} from "vant";

export default {
  name: '',
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: ['parentData'],
  data() {
    return {
      hotInfo: [],
      isok: false,//弹出框的隐藏与显示
      search: [] // 搜索记录
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.getHots()
    this.getsearch()
  },
  methods: {
    async getHots() {
      const hotInfoStr = localStorage.getItem('hotInfoStr')
      const timestamp = Math.floor(Date.now() / 1000);
      let isGet = true
      if (typeof hotInfoStr !== "undefined" && hotInfoStr !== null && hotInfoStr.trim() !== "") {
        const hotInfo = JSON.parse(hotInfoStr)
        if (hotInfo.expire >= timestamp) {
          this.hotInfo = hotInfo.data
          isGet = false
        }
      }
      if (isGet) {
        const res = await getHot()
        const data = res.result
        this.hotInfo = data
        localStorage.setItem('hotInfoStr', JSON.stringify({
          'data': data,
          'expire': timestamp + 86400
        }))
      }
    },
    onDelete() {
      Dialog.confirm({
        message: this.$t('delHistoryTip'),
        closeOnClickOverlay: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        confirmButtonColor: 'red',
        cancelButtonColor: 'black',
        className: 'alert'
      })
          .then(() => {
            localStorage.removeItem('search');
            this.getsearch()
          })
          .catch(() => {

          });
    },
    getsearch() {
      let str = localStorage.getItem('search');
      if (str == null) {
        this.search = []
      } else {
        this.search = str.split(',');
      }
    },
    toSearch(value) {
      this.$store.dispatch('getSearchValue', value)
      this.$router.push({
        path: '/search/recommendList',
        query: {
          kw: value
        }
      })

      let existingInfo = localStorage.getItem('search')
      if (existingInfo) {
        let history = existingInfo.split(',')
        // 判断是否已经在历史记录中，如果是则添加到头部
        if (history.includes(value)) {
          history = history.filter(element => element !== value);
        }

        history.unshift(value)
        // 只保留20条数据
        if (history.length > 20) {
          history.splice(20);
        }

        existingInfo = history.join(',')
      } else {
        existingInfo = value;
      }
      localStorage.setItem('search', existingInfo)
    }
  }

}
</script>
<style lang="scss" scoped>
//搜素历史样式
.searchHistory {
  font-family: PingFang SC;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: var(--title-color);
  line-height: 25px;
  font-size: 18px;
  padding: 0 10px;
}

.historyHead {
  display: flex;
  margin: 16px 0;
  justify-content: space-between;
}
//.historyHead > div:first-child {
//  margin: 0 155px 0 0px;
//}

.historys {
  display: flex;
  flex-wrap: wrap;
}

.historys div {
  background-color: var(--minor-color);
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  color: var(--minor-text-color);
  padding: 9px 12px 5px 12px;
  margin-right: 10px;
  margin-bottom: 10px;
}

// 推荐
.recommend {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: var(--title-color);
  padding: 10px;
}

.recommendTop {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
}

.recommendList {
  font-size: 14px;
  line-height: 16px;
  color: var(--minor-text-color);
}

.recommendList-name {
  padding: 5px 0;
  display: flex;
}

.recommendList-name > div:first-child {
  padding-right: 10px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

.recommendList-hint {
  font-size: 11px;
  color: var(--minor-text-color);
  line-height: 16px;
}

/deep/ .alert {
  background-color: var(--main-color);
}

/deep/ .van-dialog__message {
  color: var(--title-color);
}

/deep/ .van-tag--default {
  color: var(--minor-text-color);
  background-color: var(--minor-color);
  padding: 0.1rem 0.2rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}
</style>
